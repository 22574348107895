import React, { Component } from 'react';
//import {slateGreen} from './colors'

class InfoCard extends Component {
  render() {
    return  (
      <div className="textWrapper">
        <h1>Välkommen tillbaka!</h1>
        <p><b>Tänk på att vara försiktig när du plockar svamp,</b> eftersom det kan finnas giftiga förväxlingssvampar. Det finns ingen garanti för att den rapporterade svampen är korrekt artbestämd.</p>
        <p>Via varje svamps position länkar vi till mer information om arten på <a href="http://svampguiden.com/">svampguiden.se</a>. Där kan du se bilder och förväxlingssvampar.</p>
      </div>
    );
  }
}
export default InfoCard;
