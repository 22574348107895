import React from 'react';
import ReactDOM from 'react-dom';

/* STYLE IMPORTS */
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import './index.css';

import App from './App';
import OnboardingCard from 'components/OnboardingCard';
import SwishCard from 'components/SwishCard';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <SwishCard />
    <OnboardingCard />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
