/* External packages */
import React, { PureComponent } from "react";
import L from "leaflet";
import {
  Map,
  Marker,
  Popup,
  TileLayer,
  Circle,
  ZoomControl
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
/* AWS and Amplify packages */
import Amplify, { I18n, Analytics } from "aws-amplify";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui/dist/style.css";
import { AmplifyAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";

import './index.css';
/* Hamburger animation css*/
import "hamburgers/dist/hamburgers.css";

/* Own components and vars */
import {
  mapConfig,
  markers,
  distance,
  getPosition,
  getAWSdata,
  getBERRYdata,
  getMETdata,
  convertObservationToMarkers,
  prettyDateString,
  addDistanceToObservations,
  filterObservationsByDate
} from "helper/utils";
import {
  standardIcon,
  icon720,
  icon2959,
  icon3772,
  icon4404,
  icon4763,
  icon4786,
  icon4977,
  icon5881,
  icon5949,
  icon6031,
  icon245506,
  icon3213,
  icon4370,
  icon4723,
  icon239066,
  icon245630,
  icon2912,
  icon5836,
  icon223136,
  icon223129,
  icon221157,
  icon223156,
  icon221160,
  metIcon,
  clusterIcon
} from "components/markerIcons";
import InfoCard from "components/InfoCard";
import { ReactComponent as UserNavButton } from "components/Location.svg";

/* Radio button components */
import { Radio, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
const GreenRadio = withStyles({
  root: {
    color: "#5F7E5F",
    "&$checked": {
      color: "#5F7E5F"
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);
const GreenCheckbox = withStyles({
  root: {
    color: "#5F7E5F",
    "&$checked": {
      color: "#5F7E5F"
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

/* Main config of AWS */
Amplify.configure(awsconfig);
/* API Config for AWS */
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "Observations API",
        endpoint: "https://atute5gfo2.execute-api.eu-central-1.amazonaws.com/p"
      }
    ]
  }
});

const authScreenLabels = {
  se: {
    "Sign Up": "Skapa ett konto",
    "Sign Up Account": "Skapa ett nytt konto",
    "Sign Out": "Logga ut",
    "Sign In": "Logga in",
    "Sign in": "Logga in",
    "Username *": "Användarnamn",
    "Password *": "Lösenord",
    "Forgot your password? ": "Glömt ditt lösenord? ",
    "Reset password": "Återställ ditt lösenord",
    "Reset your password": "Återställ ditt lösenord",
    "Send Code": "Skicka återställningskod",
    "Back to Sign In": "Tillbaka till inloggning",
    "Sign in to your account": "Logga in på ditt konto",
    "No account? ": "Inget konto? ",
    "Create Account": "Skapa konto",
    "Create a new account": "Skapa ett nytt konto",
    "Create account": "Skapa konto",
    "Phone Number": "Mobilnummer",
    "Have an account? ": "Har du redan ett konto? ",
    "Enter your username": "Ditt användarnamn",
    "Enter your password": "Ditt lösenord"
  }
};
I18n.setLanguage("se");
I18n.putVocabularies(authScreenLabels);

/* Standard Icon Leaflet */
L.Icon.Default.imagePath =
  "//cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/";

const swishBase = "swish://payment";
const swishJSON =
  '{"version":1,"payee":{"value":"+46731444096"},"amount":{"value":50,"editable":true},"message":{"value":"Svampkartan"}}'; 
  /* ,"amount":{"value":50} */
const swishCallbackURL = "https://www.svampkartan.se/";
const swishCallbackParam = "result";
const swishURL =
  swishBase +
  "?data=" +
  encodeURI(swishJSON) +
  "&callbackurl=" +
  swishCallbackURL +
  "&callbackresultparameter=" +
  swishCallbackParam;
//console.log(swishURL);
var globalMarkers = markers;

class App extends PureComponent {
  state = {
    mapCenter: mapConfig.center,
    userPosition: [],
    userAccuracy: 0,
    locationEnable: false,
    hamburgerBoolean: false,
    settingsBoolean: false,
    backgroundBoolean: false,
    feedbackBoolean: false,
    observations: globalMarkers,
    berriesObservations: [],
    radioValue: "onlyMushroom",
    checkboxValue: false,
    zoom: mapConfig.zoom,
    circleVisible: false,
    circleCenter: mapConfig.center,
    circleRadius: 400,
    metObservations: []
  };
  /* EVENT HANDLERS */
  /* Info card after sign-in triggers position request & API fetch */
  handleClick = () => {
    console.log("Hej trassel!");
    var div_el = document.getElementsByClassName("infoCard");
    div_el[0].style.display = "none";
    Analytics.record({ name: "getMushroomObservations" });
    getPosition().then(res => {
      const position = res.coords;
      console.log(position);
      this.setState({ userAccuracy: res.accuracy });
      this.updatePosition(position);
      this.updateObservations(position);
    });
  };
  /* Nav icon handle */
  navClick = () => {
    this.setState({ locationEnable: false });
    getPosition().then(res => {
      const position = res.coords;
      this.updatePosition(position);
      console.log("User position updated");
    });
  };
  /* Main hamburger handle */
  hamburgerClick = () => {
    console.log("🍔 incomming");
    var current = this.state.hamburgerBoolean;
    var div_el = document.getElementsByClassName("hamburgerButton");
    const idx = div_el[0].className.indexOf("is-active");
    if (idx > -1) {
      div_el[0].className = "hamburgerButton hamburger hamburger--spin";
    } else {
      div_el[0].className =
        "hamburgerButton hamburger hamburger--spin is-active";
    }
    if (current === true) {
      this.setState({ hamburgerBoolean: false });
    } else {
      this.setState({ hamburgerBoolean: true });
    }
  };
  settingClick = () => {
    console.log("Setting 🍔");
    var current = this.state.settingsBoolean;
    if (current === true) {
      this.setState({ settingsBoolean: false });
    } else {
      this.setState({ settingsBoolean: true });
    }
  };
  backgroundClick = () => {
    console.log("Background 🍔");
    var current = this.state.backgroundBoolean;

    if (current === true) {
      this.setState({ backgroundBoolean: false });
    } else {
      this.setState({ backgroundBoolean: true });
    }
  };
  feedbackClick = () => {
    console.log("Feedback 🍔");
    var current = this.state.feedbackBoolean;

    if (current === true) {
      this.setState({ feedbackBoolean: false });
    } else {
      this.setState({ feedbackBoolean: true });
    }
  };

  /* Radio button handle*/
  handleRadioChange = event => {
    if (this.state.locationEnable === true) {
      console.log(event.target.value);
      this.setState({ radioValue: event.target.value });

      this.updateBerries(this.state.userPosition, event.target.value);
    } else {
      alert(
        "Du måste tillåta sidan att visa din plats för att detta ska funka!"
      );
    }
  };
  handleCheckboxChange = event => {
    if (this.state.locationEnable === true) {
      console.log(event.target.checked);
      if (event.target.checked) {
        const newObservationsArray = filterObservationsByDate(
          this.state.observations
        );
        this.setState({
          observations: newObservationsArray,
          checkboxValue: true
        });
      } else {
        this.setState({ observations: globalMarkers, checkboxValue: false });
      }
    } else {
      alert(
        "Du måste tillåta sidan att visa din plats för att detta ska funka!"
      );
    }
  };
  /* Not in use */
  /*
  handlePopup = e => {
    console.log(e);
    var marker_pos = e.sourceTarget._latlng;
    if (e.sourceTarget._leaflet_id !== e.target._leaflet_id) {
      marker_pos = e.target._latlng;
    }
    let zoom = e.target.options.leaflet.map._zoom;
    var rad = e.target._popup.options.className;
    if (rad>1000){rad = 1000;} else if (rad<50){rad = 50;}
    if (zoom>17){zoom = 17;} else if (zoom<14) { zoom = 14; }
    this.setState({
      circleCenter: marker_pos,
      circleRadius: rad,
      circleVisible: true,
      mapCenter: marker_pos,
      zoom: zoom
    });
  };
  */

  /* METHODS */
  updatePosition = position => {
    this.setState({
      mapCenter: [position.latitude, position.longitude],
      userPosition: [position.latitude, position.longitude],
      locationEnable: true
    });
  };

  updateObservations = position => {
    const jsonBody = {
      latitude: position.latitude,
      longitude: position.longitude
    };
    getAWSdata(jsonBody).then(rawData => {
      const apiObservations = convertObservationToMarkers(rawData.data);
      /* Add distance from user to observation */
      const finalApiObservations = addDistanceToObservations(
        jsonBody,
        apiObservations
      );
      // console.log(apiObservations);
      globalMarkers = finalApiObservations;
      this.setState({ observations: finalApiObservations, zoom: 12 });
    });
    getMETdata(jsonBody).then(rawData => {
      this.setState({ metObservations: rawData.data });
    });
  };
  updateBerries = (position, radioValue) => {
    const jsonBody = {
      latitude: position[0],
      longitude: position[1]
    };
    if (radioValue === "berriesMushrooms") {
      console.log(jsonBody);
      Analytics.record({ name: "berriesUpdate" });
      getBERRYdata(jsonBody).then(rawData => {
        const apiObservations = convertObservationToMarkers(rawData.data);
        /* Add distance from user to observation */
        for (var i = 0; i < apiObservations.length; i++) {
          const distance_to_user = distance(
            jsonBody,
            apiObservations[i].position
          );
          var distance_to_user_str = "";
          if (distance_to_user > 1000) {
            const tmp_distance_to_user = (distance_to_user / 1000).toFixed(2); // in km
            distance_to_user_str = tmp_distance_to_user
              .toString()
              .concat(" km");
          } else if (isNaN(distance_to_user)) {
            distance_to_user_str = "Okänt avstånd";
          } else {
            distance_to_user_str = distance_to_user.toString().concat(" m");
          }
          apiObservations[i].distance_to_user = distance_to_user_str;
        }
        this.setState({ berriesObservations: apiObservations });
        alert("Hurra 🎉 Nu kan du se 🍒 på kartan också!");
      });
    } else {
      this.setState({ berriesObservations: [] });
    }
  };
  /* RENDERING */
  render() {
    // State variables
    const {
      mapCenter,
      userPosition,
      userAccuracy,
      locationEnable,
      hamburgerBoolean,
      settingsBoolean,
      backgroundBoolean,
      feedbackBoolean,
      observations,
      berriesObservations,
      radioValue,
      checkboxValue,
      zoom,
      circleVisible,
      circleCenter,
      circleRadius,
      metObservations
    } = this.state;
    // create an array with marker components
    const MarkerCircle = circleVisible ? (
      <Circle center={circleCenter} radius={circleRadius} />
    ) : null;

    const LeafletMarkers = observations.map(marker => {
      let mushroomIcon = standardIcon;
      // Violgubbe (rating 3)
      if (marker.taxon_id === "720") {
        mushroomIcon = icon720;
      }
      // Kungschampinjon
      else if (marker.taxon_id === "2912") {
        mushroomIcon = icon2912;
      }
      // Fårticka (Rating 3)
      else if (marker.taxon_id === "2959") {
        mushroomIcon = icon2959;
      }
      // Kantarell + blek
      else if (marker.taxon_id === "3213" || marker.taxon_id === "3216") {
        mushroomIcon = icon3213;
      }
      // Svart trumpetsvamp, Rödgul trumpetsvamp & Trattkantareller
      else if (
        marker.taxon_id === "3772" ||
        marker.taxon_id === "3215" ||
        marker.taxon_id === "3217"
      ) {
        mushroomIcon = icon3772;
      }
      // Taggsvamp
      else if (marker.taxon_id === "4370") {
        mushroomIcon = icon4370;
      }
      // Sot­vaxskivling
      else if (marker.taxon_id === "4404") {
        mushroomIcon = icon4404;
      }
      // Läcker riska + Blodriska+ vinriska
      else if (
        marker.taxon_id === "4723" ||
        marker.taxon_id === "4724" ||
        marker.taxon_id === "4767"
      ) {
        mushroomIcon = icon4723;
      }
      // Peppar­riska (rating 3)
      else if (marker.taxon_id === "4763") {
        mushroomIcon = icon4763;
      }
      // Mandel­riska
      else if (marker.taxon_id === "4786") {
        mushroomIcon = icon4786;
      }
      // Stolt fjällskivling
      else if (marker.taxon_id === "4977") {
        mushroomIcon = icon4977;
      }
      // Guldkremla + Eskilskremla + Kantkremla
      else if (
        marker.taxon_id === "5836" ||
        marker.taxon_id === "5872" ||
        marker.taxon_id === "5943"
      ) {
        mushroomIcon = icon5836;
      }
      // Madelkremla
      else if (marker.taxon_id === "5881") {
        mushroomIcon = icon5881;
      }
      // Sillkremla
      else if (marker.taxon_id === "5949") {
        mushroomIcon = icon5949;
      }
      // Blomkålssvamp
      else if (marker.taxon_id === "6031") {
        mushroomIcon = icon6031;
      }
      // Snöbollschampinjon + knöl
      else if (marker.taxon_id === "239066" || marker.taxon_id === "2944") {
        mushroomIcon = icon239066;
      }
      // Björksopp (rating 3)
      else if (marker.taxon_id === "245506") {
        mushroomIcon = icon245506;
      }
      // Stensopp + rödbrun + finluden + Smörsopp + Grynsopp + Brunsopp + Blodsopp
      else if (
        marker.taxon_id === "245630" ||
        marker.taxon_id === "245723" ||
        marker.taxon_id === "3135" ||
        marker.taxon_id === "6088" ||
        marker.taxon_id === "6086" ||
        marker.taxon_id === "3122" ||
        marker.taxon_id === "3128"
      ) {
        mushroomIcon = icon245630;
      }
      return (
        <Marker
          position={[marker.position.latitude, marker.position.longitude]}
          /*onClick={this.handlePopup}*/ key={`marker_${marker.id}`}
          icon={mushroomIcon}
        >
          <Popup className={marker.position.accuracy}>
            <div className="tooltipBox">
              <p className={"latin"}>{`${marker.latin_name}`}</p>
              <p className="name">{`${marker.name}`}</p>
              <hr />
              <p className="tooltip">
                {`${marker.distance_to_user} från dig`}
                <br />
                {`Sågs ${prettyDateString(marker.date)} vid ${
                  marker.position.name
                } med en osäkerhet på ${marker.position.accuracy} m. `}
                <br />
                <a
                  className="tooltip_observation"
                  href={`https://www.artportalen.se/Sighting/${marker.id}`}
                  target="_blank"
                  rel=" noopener noreferrer"
                >{`Till fyndet i Artportalen 🔗`}</a>
              </p>
              <a
                className="tooltip"
                href={marker.info_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Till bilder & förväxlingssvampar på Svampguiden{" "}
                <span role="img" aria-label="link">
                  🔗
                </span>
              </a>
            </div>
          </Popup>
        </Marker>
      );
    });
    const BerriesMarkers = berriesObservations.map(marker => {
      let berryIcon = icon223136;
      /* Hjortron */
      if (marker.taxon_id === "223136") {
        berryIcon = icon223136;
      } else if (marker.taxon_id === "223129") {
        /* Åkerbär */
        berryIcon = icon223129;
      } else if (marker.taxon_id === "221157") {
        /* Blåbär*/
        berryIcon = icon221157;
      } else if (marker.taxon_id === "223156") {
        /* Hallon */
        berryIcon = icon223156;
      } else if (marker.taxon_id === "221160") {
        /* Lingon */
        berryIcon = icon221160;
      }

      return (
        <Marker
          position={[marker.position.latitude, marker.position.longitude]}
          /*onClick={this.handlePopup}*/ key={`marker_${marker.id}`}
          icon={berryIcon}
        >
          <Popup className={marker.position.accuracy}>
            <div className="tooltip">
              <p className={"latin"}>{`${marker.latin_name}`}</p>
              <p className="name">{`${marker.name}`}</p>
              <hr />
              <p className="tooltip">
                {`${marker.distance_to_user} från dig`}
                <br />
                {`Sågs ${marker.date} vid ${marker.position.name} med en osäkerhet på ${marker.position.accuracy} m. `}
                <br />
                <a
                  className="tooltip_observation"
                  href={`https://www.artportalen.se/Sighting/${marker.id}`}
                  target="_blank"
                  rel=" noopener noreferrer"
                >{`Till fyndet i Artportalen 🔗`}</a>
              </p>
              <a
                className="tooltip"
                href={marker.info_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Se fakta om bäret på Artfakta.se{" "}
                <span role="img" aria-label="link">
                  🔗
                </span>
              </a>
            </div>
          </Popup>
        </Marker>
      );
    });
    const MetMarkers = metObservations.map(marker => {
      /* Here can sum be calculated later on*/
      const arrSum = arr => arr.reduce((a, b) => a + b, 0);
      const arr = arrSum(marker.value);
      const meticon = metIcon(marker);
      return (
        <Marker
          position={[marker.position.latitude, marker.position.longitude]}
          key={`marker_${marker.id}`}
          icon={meticon}
        >
          <Popup className="metMarker">
            <div className="tooltip">
              <p className={"latin"}>{`SMHI Väderstation`}</p>
              <p className="name">{`${marker.name}`}</p>
              <hr />
              <p className="tooltip">
                {`De senaste två veckorna har det regnat`}
                <br />
                {`${arr.toFixed(0)} mm`}
              </p>
              <p>Datan kommer från SMHI</p>
            </div>
          </Popup>
        </Marker>
      );
    });

    const UserMarker = locationEnable ? (
      <Marker position={userPosition} key={"User position"}>
        <Popup>
          <span>{"Din plats"}</span>
          <br />
          <span>{`Latitud: ${userPosition[0]}`}</span>
          <br />
          <span>{`Longitude: ${userPosition[1]}`}</span>
        </Popup>
      </Marker>
    ) : null;
    const UserCircle = locationEnable ? (
      <Circle center={userPosition} radius={userAccuracy} />
    ) : null;
    const HamburgerCard = hamburgerBoolean ? (
      <div className="hamburgerWrapper">
        <h1 className="hamburgerText">Meny</h1>
        <hr className="hamburgerLine" />
        <p className="hamburgerText" onClick={this.backgroundClick}>
          Om Svampkartan & Artportalen
        </p>
        <p className="hamburgerText" onClick={this.settingClick}>
          Inställningar & Bounsmaterial{" "}
          <span role="img" aria-label="stars">
            ✨
          </span>
        </p>
        <p className="hamburgerText" onClick={this.feedbackClick}>
          Skicka feedback
        </p>
        <div className="signoutButton">
          <hr />
          <br />
          <AmplifySignOut />
        </div>
      </div>
    ) : null;
    const SettingsCard = settingsBoolean ? (
      <div className="settingWrapper">
        <div
          className="settingButton hamburger hamburger--arrow is-active"
          onClick={this.settingClick}
        >
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </div>
        <h1 className="hamburgerText">Inställningar & bonusmaterial</h1>
        <div className="container">
          <hr className="hamburgerLine" />
          <p className="hamburgerSmall">Visa på kartan</p>
          <div className="item">
            <p className="hamburgerText">Svampars positioner</p>
            <GreenRadio
              checked={radioValue === "onlyMushroom"}
              onChange={this.handleRadioChange}
              value="onlyMushroom"
              name="radio-button-demo"
              inputProps={{ "aria-label": "Endast Svampar" }}
            />
          </div>
          <div className="item">
            <p className="hamburgerText">
              Bär & svampars positioner{" "}
              <span role="img" aria-label="stars">
                ✨
              </span>
            </p>
            <GreenRadio
              checked={radioValue === "berriesMushrooms"}
              onChange={this.handleRadioChange}
              value="berriesMushrooms"
              color="#5F7E5F"
              name="radio-button-demo"
              inputProps={{ "aria-label": "Bär och Svampar" }}
            />
          </div>
          <hr className="hamburgerLine" />
          <p className="hamburgerSmall">Visa på kartan</p>
          <div className="item">
            <p className="hamburgerText margin-top margin-bot-0">
              Svamptillgång enligt prognos{" "}
              <span role="img" aria-label="stars">
                ✨
              </span>
            </p>
            <GreenCheckbox
              checked={checkboxValue}
              onChange={this.handleCheckboxChange}
              color="#5F7E5F"
              name="Svampprognos"
              inputProps={{ "aria-label": "Svampprognos" }}
            />
          </div>
        </div>
        <div className="swishBox">
          <div className="swish-container">
            <p className="item swishText">
              Prognosen baseras på senaste veckornas väder och färska
              observationer.
            </p>
            <p className="item swishText margin-top">
              Hjälp oss göra kartan ännu bättre! Swisha en slant till Carl
            </p>
            <div className="item">
              <a
                href={swishURL}
                target="_blank"
                rel=" noopener noreferrer"
                className="button p2 swish-link"
              >
                Stötta Svampkartan
              </a>
            </div>
          </div>
        </div>
      </div>
    ) : null;
    const BackgroundCard = backgroundBoolean ? (
      <div className="settingWrapper">
        <div
          className="settingButton hamburger hamburger--arrow is-active"
          onClick={this.backgroundClick}
        >
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </div>
        <h1 className="hamburgerText">Om Svampkartan & Artportalen</h1>
        <hr className="hamburgerLine" />
        <p className="hamburgerText">
          Här hittar du matsvampars positioner hämtade från{" "}
          <a href="https://artportalen.se">Artportalen</a> som ägs och förvaltas
          av SLU Artdatabanken. Artportalens data kommer från svampjägares
          noterade observationer i Sverige ända sedan 70-talet.
        </p>
        <p className="hamburgerText">
          Observera att det inte finns någon garanti för att den rapporterade
          svampen är korrekt artbestämd. Du måste själv ta ansvar för att göra
          en korrekt artbestämning så att du inte riskerar att äta någon giftig
          svamp.
        </p>
        <p className="hamburgerText">
          Tänk på att sharing is caring. Dela med dig av dina ställen till andra
          precis som andra har delat med sig sina ställen till dig. Registrera
          dina svampobservationer till Artportalen direkt i mobilen på{" "}
          <a href="artfakta.se/rapportera">artfakta.se/rapportera</a> eller på{" "}
          <a href="https://artportalen.se">artportalen.se</a>.
        </p>
        <div className="swishBox-small">
          <p className="swishText">
            Hjälp oss göra kartan ännu bättre! Swisha en slant till Carl
          </p>
          <a href={swishURL} target="_blank" rel=" noopener noreferrer">
            <button className="button">Stötta Svampkartan</button>
          </a>
        </div>
      </div>
    ) : null;
    const FeedbackCard = feedbackBoolean ? (
      <div className="settingWrapper">
        <div
          className="settingButton hamburger hamburger--arrow is-active"
          onClick={this.feedbackClick}
        >
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </div>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdy_RSrkQ_mcJELzeDs8PmK6O8Yn5rhKfUR5DWtzwW0f1cBgg/viewform"
          title="Feedback"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    ) : null;

    return (
      <AmplifyAuthenticator initialAuthState="signup">
        <div className="mapWrapper">
          <div className="userNavButton" onClick={this.navClick}>
            <UserNavButton />
          </div>
          <div
            className="hamburgerButton hamburger hamburger--spin"
            onClick={this.hamburgerClick}
          >
            <div className="hamburger-box">
              <div className="hamburger-inner"></div>
            </div>
          </div>

          <div>
            {HamburgerCard}
            {BackgroundCard}
            {SettingsCard}
            {FeedbackCard}
          </div>
          <div className="map">
            <Map
              center={mapCenter}
              zoom={zoom}
              zoomControl={false}
              maxZoom={17}
              minZoom={9}
              zoomAnimation={true}
              className="map__reactleaflet"
            >
              <TileLayer
                url="https://c.tile.opentopomap.org/{z}/{x}/{y}.png"
                // url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://www.opentopomap.org/copyright">OpenTopoMap</a>'
              />
              <ZoomControl position="bottomright" />
              {MarkerCircle}
              <MarkerClusterGroup
                showCoverageOnHover={false}
                iconCreateFunction={clusterIcon}
              >
                {LeafletMarkers}
                {BerriesMarkers}
              </MarkerClusterGroup>
              {UserMarker}
              {UserCircle}
              {MetMarkers}
            </Map>
          </div>
          <div className="infoCard">
            <InfoCard />
            <button onClick={this.handleClick} className="button">
              Hitta svampar nära mig!
            </button>
          </div>
        </div>
      </AmplifyAuthenticator>
    );
  }
}

/*
export default withAuthenticator(App, false, [], null, signInTheme,{
  signUpConfig: {
    hiddenDefaults: ["phone_number"],
  }
});
*/
export default App;
