import React, { Component } from 'react';

/* GIF images */
import Dance from 'components/gifs/mushroommovie_dance.gif';
import Confetti from 'components/gifs/mushroommovie_confetti.gif';
import Sad from 'components/gifs/mushroommovie_sad.gif';

/* Post payment details to server */
import {postSwishDetails} from 'helper/utils';

class SwishCard extends Component {
  state = {
    querystring:false,
    payStatus:'none'
  };
  checkURL = () => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('result')) {
      this.setState({querystring:true});
      const response = JSON.parse(decodeURI(query.get('result')));
      postSwishDetails(response).then((res) => {
        console.log('YAY! Vi har ett svar från Swish!');
        this.setState({payStatus:response.result})
      });
    } else {
      this.noQuery();
    }
  }
  componentDidMount() {
    this.checkURL();
  }
  /* EVENT HANDLERS */
  handleClick = () => {
    console.log('Nu tar vi bort swish kortet (och onboardingkortet)!');
    var div_el = document.getElementsByClassName("landingPage");
    div_el[0].style.display = "none";
    var div_el_s = document.getElementsByClassName("swishPage");
    div_el_s[0].style.display = "none";
  };
  noQuery = () => {
    console.log('Nu tar vi bort BARA swish kortet!');
    var div_el_s = document.getElementsByClassName("swishPage");
    div_el_s[0].style.display = "none";
  };
  render() {
    const {querystring,payStatus} = this.state;

    const SwishStatus = () => {
      var gif = Dance;
      var title = 'Lorem'
      var paragraph = 'ipsum';
      if (payStatus === 'paid') {
        gif = Confetti;
        title = 'Tack för ditt bidrag!';
        paragraph = 'Nu kan du ta del av alla bounsfunktioner så som att se bär på kartan och se en prognos på vilka svampar du kan hitta just nu.';
      } else if (payStatus === 'notpaid') {
        gif = Dance;
        title = 'Oh nej!';
        paragraph = 'Du avbröt betalningen på ett eller annat sätt. Försök igen för att bli lika glad som den här svampen';
      } else if (payStatus === 'error') {
        gif = Sad;
        title = 'Något gick fel under transaktionen!';
        paragraph = 'Mest troligt tappade du internetuppkoppling medan betalningen skulle genomföras. Vänligen kolla din bank om transaktionen gick igenom. Försök igen om betalningen inte gick igenom.';
      }
      return (
        <div className="swishCard">
          <img src={ gif } alt="" className="gifImg"/>
          <div className="swishTextWrapper">
            <h1>{title}</h1>
            <p>{paragraph}</p>
          </div>
          <button onClick={this.handleClick} className="button">
            Till svampkartan
          </button>
        </div>)};

    return  (
      <div className='swishPage'>
        {/*<div className="bgImage">
          <img src={ Background } alt="" className="bgImage"/>
        </div>*/}
        <div className="swishBackground">
            {querystring && <SwishStatus />}
        </div>
      </div>
    );
  }
}
export default SwishCard;
