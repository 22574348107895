import { API, Auth } from 'aws-amplify';
/* VARIABLES */
export const markers = [
  {
    id: 0,
    position: {
      latitude: 57.672307,
      longitude: 11.972996,
      accuracy: 5000,
      name: 'Änggårdsbergen'
    },
    name: 'Kantarell',
    date: '1993-08-01T00:00:00',
    taxon_id: "3213"
  },
  {
    id: 1,
    position: {
      latitude: 57.672307,
      longitude: 11.953,
      accuracy: 25,
      name: 'Felstavad plats'
    },
    name: 'Stensopp/Karl-Johan',
    date: '2019-07-30T00:00:00',
    taxon_id: "245630"
  },
  {
    id: 2,
    position: {
      latitude: 57.67407,
      longitude: 11.983,
      accuracy: 500,
      name: 'Salfjället'
    },
    name: 'Blek Taggsvamp',
    date: '2017-09-03T00:00:00',
    taxon_id: "4370"
  },
  {
    id: 3,
    position: {
      latitude: 57.70407,
      longitude: 11.993,
      accuracy: 150,
      name: 'Götet'
    },
    name: 'Random god svamp',
    date: '2017-09-03T00:00:00',
    taxon_id: "2917"
  }
];

export const mapConfig = {
  center: [57.672307, 11.952996],
  zoom: 13
};

const EARTH_RADIUS = 6371e3; // meters

export const distance = (source, target) => {
	const source_lat_radian = source.latitude / 180 * Math.PI;
	const source_lng_radian = source.longitude / 180 * Math.PI;

	const target_lat_radian = target.latitude / 180 * Math.PI;
	const target_lng_radian = target.longitude / 180 * Math.PI;

	const lat_delta = target_lat_radian - source_lat_radian;
	const lng_delta = target_lng_radian - source_lng_radian;
	const alpha =		Math.sin(lat_delta / 2) * Math.sin(lat_delta / 2) +
                  Math.cos(source_lat_radian) * Math.cos(target_lat_radian) *
                  Math.sin(lng_delta / 2) * Math.sin(lng_delta / 2);

	const circle = 2 * Math.atan2(Math.sqrt(alpha), Math.sqrt(1 - alpha));
	return (EARTH_RADIUS * circle).toFixed(2); // in meters
};

/* FUNCTIONS */
export function getPosition() {
  // Simple wrapper
  return new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition(res, rej);
  });
}

export async function getAWSdata(data = {}) {
  let apiName = 'Observations API';
  let path = '/observations';
  let myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json',
        Accept: '*/*'
      },
      response: true,
      queryStringParameters: data
  }
  const res = API.get(apiName, path, myInit);
  const responseStatus = await res;
  console.log(`Mushroom server return status ${responseStatus.status}`);
  return await res;
}
export async function getBERRYdata(data = {}) {
  let apiName = 'Observations API';
  let path = '/berries-observations';
  let myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json',
        Accept: '*/*'
      },
      response: true,
      queryStringParameters: data
  }
  const res = API.get(apiName, path, myInit);
  const responseStatus = await res;
  console.log(`Berry server return status ${responseStatus.status}`);
  return await res;
}
export async function getMETdata(data = {}) {
  let apiName = 'Observations API';
  let path = '/met-observations';
  let myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json',
        Accept: '*/*'
      },
      response: true,
      queryStringParameters: data
  }
  const res = API.get(apiName, path, myInit);
  const responseStatus = await res;
  console.log(`MET Server return status ${responseStatus.status}`);
  return await res;
}
export async function postSwishDetails(data = {}) {
  let userInfo = await Auth.currentUserInfo();
  let query = {
    payStatus:data.result,
    username:userInfo.username,
    fullMsg:JSON.stringify(data.userInfo)
  }
  let apiName = 'Observations API';
  let path = '/swish';
  let myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json',
        Accept: '*/*'
      },
      response: true,
      queryStringParameters: query
  }
  const res = API.post(apiName, path, myInit);
  const responseStatus = await res;
  console.log(`Swish server return status ${responseStatus.status}`);
  return await res;
}
export function convertObservationToMarkers(observations) {
  const observationsArray = [];
  for (const mushroom in observations) {
    observationsArray.push(...observations[mushroom]);
  }
  return observationsArray;
}

export function prettyDateString(date) {
  /* Date fix to nicer format, Assuming a date string in the format YYYY-MM-DD */
  const tmpDate = new Date(Date.parse(date));
  var strDate = tmpDate.toLocaleString('sv-SE',{'day':'2-digit','month':'long','year':'numeric'});
  return strDate;
}

export function addDistanceToObservations(userPosition,observationsArray) {
  for (var i = 0; i < observationsArray.length; i++) {
    /* Distance to user */
    const distance_to_user = distance(userPosition, observationsArray[i].position);
    var distance_to_user_str = '';
    if (distance_to_user > 1000) {
      const tmp_distance_to_user = (distance_to_user/1000).toFixed(2); // in km
      distance_to_user_str = tmp_distance_to_user.toString().concat(' km');
    }
    else if (isNaN(distance_to_user)) {
      distance_to_user_str = 'Okänt avstånd';
    }
    else {
      distance_to_user_str = distance_to_user.toString().concat(' m');
    }
    observationsArray[i].distance_to_user  = distance_to_user_str;
  }
  return observationsArray;
}

export function filterObservationsByDate(observationsArray) {
  const oneDay = 1000 * 60 * 60 * 24;
  const dateFilteredObservations = [];
  const today = new Date();
  for (var i = 0; i < observationsArray.length; i++) {
    const tmpDate = new Date(Date.parse(observationsArray[i].date));
    const observationDate = new Date(today.getFullYear(),tmpDate.getMonth(),tmpDate.getDate());
    const diff = Math.round(today.getTime() - observationDate.getTime()) / (oneDay);
    if (Math.abs(diff.toFixed(0))<=14 ) {
      dateFilteredObservations.push(observationsArray[i]);
    }
  }
  return dateFilteredObservations;
}
