import React, { Component } from 'react';
import Background from 'components/background_landingpage_web.png';
//import {slateGreen} from './colors'

class OnboardingCard extends Component {
  /* EVENT HANDLERS */
  handleClick = () => {
    console.log('Nu tar vi bort onboardingkortet!');
    var div_el = document.getElementsByClassName("landingPage");
    div_el[0].style.display = "none";
  };
  render() {
    return  (
      <div className='landingPage'>
        <div className="bgImage">
          <img src={ Background } alt="" className="bgImage"/>
        </div>
        <div className="onboardingBackground">
          <div className="onboardingCard">
            <div className="textWrapper">
              <h1>Välkommen!</h1>
              <p>Här hittar du matsvampars positioner hämtade från <a href="https://artportalen.se">Artportalen</a> som ägs och förvaltas av SLU Artdatabanken. Datan kommer från svampjägares observationer i Sverige ända sedan 70-talet.</p>
              <p className="bold">Var alltid försiktig när du plockar svamp. Det finns ingen garanti för att den rapporterade svampen är korrekt artbestämd.</p>
              <p>Via varje svampposition länkar vi till mer information om arten på <a href="https://svampguiden.se">svampguiden.se</a>. Där kan du se giftiga förväxlingssvampar. </p>
              <p>Vi utvecklar kartan ständigt, så hör gärna av dig med feedback!</p>
              <p className="italic">God jaktlycka önskar Carl och Cecilia</p>
            </div>
            <button onClick={this.handleClick} className="button">
              Till svampkartan
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default OnboardingCard;
