// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:704f89de-3e22-488a-b047-ef6c49153ec8",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_A7cGIQ4EQ",
    "aws_user_pools_web_client_id": "pmlr6n0v25ifv26erp6rrqsdp",
    "oauth": {},
    "aws_mobile_analytics_app_id": "60d108340b804879bd176d0ac872098a",
    "aws_mobile_analytics_app_region": "eu-central-1"
};


export default awsmobile;
