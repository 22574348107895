import L from 'leaflet'

export const standardIcon = new L.Icon({
  iconUrl: require('components/icons/standard_mushroom_icon.png'),
  iconSize: [50, 50]
  //className: 'mushroom-icon',
  //html: '<img src="https://storage.googleapis.com/tryffelsvin/marker_image.png" style="height:50px;width:50px">'
});

/* MUSHROOMS */
export const icon3213 = new L.Icon({
  iconUrl: require('components/icons/3213.png'),
  iconSize: [50, 50]
});
export const icon4370 = new L.Icon({
  iconUrl: require('components/icons/4370.png'),
  iconSize: [50, 50]
});
export const icon245630 = new L.Icon({
  iconUrl: require('components/icons/245630.png'),
  iconSize: [50, 50]
});
export const icon239066 = new L.Icon({
  iconUrl: require('components/icons/239066.png'),
  iconSize: [50, 50]
});
export const icon2912 = new L.Icon({
  iconUrl: require('components/icons/2912.png'),
  iconSize: [50, 50]
});
export const icon4723 = new L.Icon({
  iconUrl: require('components/icons/4723.png'),
  iconSize: [50, 50]
});
export const icon5836 = new L.Icon({
  iconUrl: require('components/icons/5836.png'),
  iconSize: [50, 50]
});
export const icon720 = new L.Icon({
  iconUrl: require('components/icons/720.png'),
  iconSize: [50,50]
});
export const icon2959 = new L.Icon({
  iconUrl: require('components/icons/2959.png'),
  iconSize: [50,50]
});
export const icon3772 = new L.Icon({
  iconUrl: require('components/icons/3772.png'),
  iconSize: [50,50]
});
export const icon4404 = new L.Icon({
  iconUrl: require('components/icons/4404.png'),
  iconSize: [50,50]
});
export const icon4763 = new L.Icon({
  iconUrl: require('components/icons/4763.png'),
  iconSize: [50,50]
});
export const icon4786 = new L.Icon({
  iconUrl: require('components/icons/4786.png'),
  iconSize: [50,50]
});
export const icon4977 = new L.Icon({
  iconUrl: require('components/icons/4977.png'),
  iconSize: [50,50]
});
export const icon5881 = new L.Icon({
  iconUrl: require('components/icons/5881.png'),
  iconSize: [50,50]
});
export const icon5949 = new L.Icon({
  iconUrl: require('components/icons/5949.png'),
  iconSize: [50,50]
});
export const icon6031 = new L.Icon({
  iconUrl: require('components/icons/6031.png'),
  iconSize: [50,50]
});
export const icon245506 = new L.Icon({
  iconUrl: require('components/icons/245506.png'),
  iconSize: [50,50]
});

/* BERRIES */
// Hjortron
export const icon223136 = new L.Icon({
  iconUrl: require('components/icons/hjortron_223136.png'),
  iconSize: [50,50]
});
export const icon223129 = new L.Icon({
  iconUrl: require('components/icons/akerbar_223129.png'),
  iconSize: [50,50]
});
export const icon221157 = new L.Icon({
  iconUrl: require('components/icons/blueberry_221157.png'),
  iconSize: [50,50]
});
export const icon223156 = new L.Icon({
  iconUrl: require('components/icons/hallon_223156.png'),
  iconSize: [50,50]
});
export const icon221160 = new L.Icon({
  iconUrl: require('components/icons/lingon_221160.png'),
  iconSize: [50,50]
});
/* MET */
export const metIcon = function (marker) {
  const arrSum = arr => arr.reduce((a,b) => a + b, 0);
  const arr = arrSum(marker.value);
  /*
  const rainyBlue = '#0087F5';
  const paleGrey = '#eaf2ff';
  */
  var style = 'border-radius:50px; background-color:#0087F5;color:white; font-family: "avenir heavy";text-shadow: 0px 1px 3px #2e452e; font-size:1.5em; position:absolute; text-align: center; width: 100%; bottom:12px;';
  var icon_size = [50, 50];

  if (arr<20) {
    style = 'border-radius:50px; background-color:#d1c097;color:white; font-family: "avenir heavy"; text-shadow: 0px 1px 3px #2e452e; font-size:1.3em; position:absolute; text-align: center; width: 100%; bottom:5px;';
  }

  return L.divIcon({
  iconSize: icon_size,
  html: `<span className='met-icon-text' style='${style}'>${arr.toFixed(0)}</span>`,
  className: 'met-icon'
  })
};

export const clusterIcon = function (cluster) {
  var cluster_nr = cluster.getChildCount();
  var style = 'color:white; font-family: "avenir heavy";text-shadow: 0px 1px 3px #2e452e; font-size:1.5em; position:absolute; text-align: center; width: 100%; bottom:5px;';
  var cluster_img = require('components/icons/Cluster10.png');
  var icon_size = [70, 70];
  if (cluster_nr<10) {
    style = 'color:white; font-family: "avenir heavy"; text-shadow: 0px 1px 3px #2e452e; font-size:1.5em; position:absolute; text-align: center; width: 100%; bottom:5px;';
    cluster_img = require('components/icons/Cluster1.png');
    icon_size = [58, 54];
  } else if (cluster_nr>=100) {
    style = 'color:white; font-family: "avenir heavy"; text-shadow: 0px 1px 3px #2e452e; font-size:1.3em; position:absolute; text-align: center; width: 100%; bottom:7px;';
    cluster_img = require('components/icons/Cluster100.png');
  }
  return L.divIcon({
  iconSize: icon_size,
  html: `<span className='cluster-icon-text' style='${style}'>${cluster_nr}</span><img src='${cluster_img}'></img>`,
  className: 'cluser-icon-few'
  })
};

/*
export const userIcon = new Leaflet.Icon({
  iconUrl: 'https://storage.googleapis.com/tryffelsvin/nav_icon.png',
  iconSize: [50, 50]
});
*/
